// ******************************************************
// Inputs
// ******************************************************

.stn-input {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 15px;
  border: solid 1px #d7d7d7;
  color: $gray-text;
  font-size: 16px;
  line-height: 50px;
  height: 50px; }

textarea {
  &:disabled {
    opacity: .3; } }

.open-message-textarea {
  input {
    display: inline-block !important;
    margin-bottom: 0 !important;
    width: auto !important; } }

// ******* st-input
.st-input {
  line-height: 22px;
  padding: 10px 15px;
  outline: none;
  border: 1px solid $gray-lighter;
  @include br($border-radius-base); }

// ******* st-textarea
.st-textarea {
  line-height: 22px;
  padding: 10px 15px;
  outline: none;
  border: 1px solid $gray-lighter;
  @include br($border-radius-base);
  max-width: 100%;
  width: 100%; }

// ******* Input Wrapper
.input-wrapper {
  width: 100%;
  margin-bottom: 1.5rem;
  .st-input {
    width: 100%; }
  .st-textarea {
    width: 100%;
    max-width: 100%;
    max-height: 200px;
    overflow: scroll; } }

// ******* File Input
.file-input {
  display: inline-block;
  text-align: left;
  width: 100%;
  position: relative;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  > {}
  [type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer; }
  .file-input-button {
    display: inline-block;
    cursor: pointer;
    background: #eee;
    padding: 8px 16px;
    border-radius: 2px;
    margin-right: 8px;
    @include transition(all .3s ease); }
  &:hover > .file-input-button {
    background: $green;
    color: white; }
  > .file-input-label {
    color: #333;
    white-space: nowrap;
    opacity: .3; }
  &.-chosen > .file-input-label {
    opacity: 1; } }

// ******* Image Upload
.upload-image {
  // imageUploadBtn
  .imageUploadBtn {
    width: 100%;
    display: block;
    background: $green;
    color: $white;
    padding: .75rem 0;
    text-align: center;
    outline: none;
    border: none;
    border-bottom-right-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    @include transition(all .3s ease);
    &:hover {
      background: rgba($green, .5); } }
  // input
  input[type=file] {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden; }
  // fileName
  .fileName {
    padding-top: 10px;
    font-weight: 300; }
  // Preview image
  .preview {
    position: relative;
    width: 100%;
    min-height: 100px;
    background: $gray-lighter;
    overflow: hidden;
    border-top-right-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    img {
      width: 100%;
      object-fit: contain; }
    .loadingLogo {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -35px;
      margin-top: -35px;
      width: 70px;
      height: 70px;
      border: 5px solid #69CD9B;
      border-top: 5px solid #2FDEC1;
      border-radius: 100%;
      animation: spin 500ms linear infinite; } } }

// ******* select label
input[type="radio"] {
  display: none;
  & + .select-label:before {
    background-color: #FFF;
    border: 1px solid #000; }
  & + .select-color-item > .color-dot {
    border: 1px solid $gray-light-2;
    background: $gray-color;
    border-radius: 100%;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 1em;
    cursor: pointer;
    text-align: center;
    @include vcenter;
    @include transition(all .3s ease); }
  &:checked + .select-color-item {
    border: 1px solid $gray-color; } }
