// ******************************************************
// Buttons
// ******************************************************

// ******* btn
.btn {
  padding: 17px 60px;
  @include br(0);
  border: solid 1px $gray-light-2;
  color: #4a4a4a;
  @include transition(all .3s ease);
  &:hover {
    background-color: $color-primary-darker;
    color: $white; } }

// ******* btn standard
.btn-stn {
  padding: 13px 21px;
  height: 45px;
  line-height: 45px;
  border: solid 1px $gray-color;
  font-size: 15px;
  text-align: center;
  color: $primary-text-color;
  text-decoration: none;
  @include transition(all .3s ease);
  &:hover {
    background-color: $color-primary-darker;
    color: $white;
    text-decoration: none; }
  &:focus, &:active {
    text-decoration: none; } }

// ********** read more btn

.read-more-btn {
  font-size: 18px;
  text-align: center;
  color: #12181f;
  background-color: #FFF;
  border: none; }
