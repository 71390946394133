// ******************************************************
// Buttons
// ******************************************************

.tabs {
  .tabs-control {
    li {
      &.disabled {
        &:hover {
          cursor: not-allowed;
          background: $gray-lighter !important;
          color: $gray-light !important; } } } }
  .tabs-items {
    .tab-item {
      display: none;
      &.active-tab {
        display: block; } } } }
