.SumoSelect {
  p {
    margin: 0;
  }
  min-width: 200px;
  width: 100%;
  cursor: pointer;
}

.SelectBox {
  padding: 12px 15px;
}

.sumoStopScroll {
  overflow: hidden;
}

/* Filtering style */

.SumoSelect {
  .hidden {
    display: none;
  }
  .search-txt {
    display: none;
    outline: none;
  }
  .no-match {
    display: none;
    padding: 6px;
  }
  &.open {
    .search-txt {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 5px 8px;
      border: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 5px;
    }
    > .search > {
      span, label {
        visibility: hidden;
      }
    }
  }
}

/*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */

.SelectClass, .SumoUnder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity = 0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

.SelectClass {
  z-index: 1;
}

.SumoSelect {
  > {
    .optWrapper > .options li.opt label, .CaptionCont {
      cursor: pointer;
      user-select: none;
      -o-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
    }
  }
  .select-all > label {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }
  display: inline-block;
  position: relative;
  outline: none;
  &:focus > .CaptionCont, &:hover > .CaptionCont {
    box-shadow: 0 0 2px #7799D0;
    border-color: #979797;
  }
  &.open {
    > .CaptionCont {
      box-shadow: 0 0 2px #7799D0;
      border-color: #979797;
    }
    > .optWrapper {
      top: 60px;
      display: block;
      &.up {
        top: auto;
        bottom: 100%;
        margin-bottom: 5px;
      }
    }
  }
  > {
    .CaptionCont {
      position: relative;
      border: 1px solid #979797;
      min-height: 14px;
      background-color: #fff;
      margin: 0;
      > {
        span {
          display: block;
          padding-right: 30px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &.placeholder {
            color: #979797;
          }
        }
        label {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 30px;
          margin-bottom: 0;
          margin-right: 5px;
          > i {
            background-image: url('../img/website/down-arrow.svg');
            background-position: center center;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .optWrapper {
      display: none;
      z-index: 1000;
      top: 30px;
      width: 100%;
      position: absolute;
      left: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #979797;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
      overflow: hidden;
    }
  }
  > .optWrapper {
    ul {
      list-style: none;
      display: block;
      padding: 0;
      margin: 0;
      overflow: auto;
    }
    > .options {
      position: relative;
      /*Set the height of pop up here (only for desktop mode)*/
      max-height: 250px;
      /*height*/
    }
    &.okCancelInMulti > .options {
      border-radius: 2px 2px 0 0;
    }
    &.selall {
      > .options {
        border-radius: 0 0 2px 2px;
      }
      &.okCancelInMulti > .options {
        border-radius: 0;
      }
    }
    > .options li {
      &.group.disabled > label {
        opacity: 0.5;
      }
      ul li.opt {
        padding-left: 22px;
      }
    }
    &.multiple > .options li ul li.opt {
      padding-left: 50px;

      > label {
        margin-bottom: 0;
      }
    }
    &.isFloating > .options {
      max-height: 100%;
      box-shadow: 0 0 100px #595959;
    }
    > .options {
      li.opt {
        padding: 12px 15px;
        position: relative;
        border-bottom: 1px solid #d9d9d9;
      }
      > li.opt:first-child {
        border-radius: 2px 2px 0 0;
      }
    }
    &.selall > .options > li.opt:first-child {
      border-radius: 0;
    }
    > .options > li.opt:last-child {
      border-radius: 0 0 2px 2px;
      border-bottom: none;
    }
    &.okCancelInMulti > .options > li.opt:last-child {
      border-radius: 0;
    }
    > .options li.opt {
      &:hover {
        background-color: #f8f8f8;
      }
      &.sel {
        background-color: #a1c0e4;
      }
    }
  }
  .select-all {
    &.sel {
      background-color: #fff;
    }
    > span {
      position: absolute;
      display: block;
      width: 14px;
      top: 0;
      bottom: 0;
      margin-left: -25px;
    }
    > span i {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 14px;
      height: 14px;
      border: 1px solid #AEAEAE;
      border-radius: 3px;
      background-color: #fff;
    }
    &.selected > span i, &.partial > span i {
      box-shadow: none;
      border-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &.selected > span i {
      background-image: url(../img/website/checkbox.svg);
    }
  }
  > .optWrapper {
    > .options li {
      label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        cursor: pointer;
        margin-bottom: 0;
      }
      span {
        display: none;
      }
      &.group > label {
        cursor: default;
        padding: 8px 6px;
        font-weight: bold;
      }
    }
    &.isFloating {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 90%;
      bottom: 0;
      margin: auto;
      max-height: 90%;
    }
    > .options li.opt.disabled {
      background-color: inherit;
      pointer-events: none;
      * {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        /* IE 5-7 */
        filter: alpha(opacity = 50);
        /* Netscape */
        -moz-opacity: 0.5;
        /* Safari 1.x */
        -khtml-opacity: 0.5;
        /* Good browsers */
        opacity: 0.5;
      }
    }
    &.multiple > .options li.opt {
      padding-left: 40px;
      cursor: pointer;
      span {
        position: absolute;
        display: block;
        width: 14px;
        top: 0;
        bottom: 0;
        margin-left: -25px;
      }
    }
  }
  > .optWrapper.multiple > .options li.opt span i {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #AEAEAE;
    border-radius: 3px;
    background-color: #fff;
  }
  > .optWrapper {
    > .MultiControls {
      display: none;
      border-top: 1px solid #ddd;
      background-color: #fff;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
      border-radius: 0 0 3px 3px;
    }
    &.multiple {
      &.isFloating > .MultiControls {
        display: block;
        margin-top: 5px;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      &.okCancelInMulti > .MultiControls {
        display: block;
        > p {
          padding: 6px;
          &:focus {
            box-shadow: 0 0 2px #a1c0e4;
            border-color: #a1c0e4;
            outline: none;
            background-color: #a1c0e4;
          }
        }
      }
      > .MultiControls > p {
        display: inline-block;
        cursor: pointer;
        padding: 12px;
        width: 50%;
        box-sizing: border-box;
        text-align: center;
        &:hover {
          background-color: #f1f1f1;
        }
        &.btnOk {
          border-right: 1px solid #DBDBDB;
          border-radius: 0 0 0 3px;
        }
        &.btnCancel {
          border-radius: 0 0 3px 0;
        }
      }
    }
    &.isFloating > .options li.opt {
      padding: 12px 6px;
    }
    &.multiple {
      &.isFloating {
        > .options li.opt {
          padding-left: 35px;
        }
        padding-bottom: 43px;
      }
      > .options li.opt.selected span i {
        background-color: white;
        box-shadow: none;
        border-color: transparent;
        background-image: url(../img/website/checkbox.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    > .CaptionCont {
      border-color: #ccc;
      box-shadow: none;
    }
  }
  .select-all {
    border-radius: 3px 3px 0 0;
    position: relative;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    padding: 8px 0 8px 40px;
    height: 45px;
    cursor: pointer;
    > {
      label{
        cursor: pointer;
        margin-bottom: 0;
        margin-top: 6px;
      }
      span i {
        cursor: pointer;
      }
    }
    &.partial > span i {
      background-color: #AEAEAE;
    }
  }
  > .optWrapper > .options li.optGroup {
    padding-left: 5px;
    text-decoration: underline;
  }
}
