// ******************************************************
// Footer
// ******************************************************


footer {
  width: 100%;
  background-color: $gray-middle; }

.footer-block {
  padding-bottom: 52px;
  width: 70%;
  margin: 0 auto;
  @include clr;
  @include r($tablet) {
    width: 100%; }
  .footer-item {
    padding-top: 52px;
    .footer-item-title {
      font-size: 14px;
      color: #12181f;
      text-transform: uppercase;
      margin-bottom: 13px;
      display: block;
      font-weight: bold; }
    .footer-contact {
      display: block;
      margin-bottom: 19px; }
    .footer-adress {
      display: block;
      margin-bottom: 28px; }
    .mail-adress {
      font-size: 14px;
      color: #4a4a4a;
      display: block;
      margin-bottom: 8px; }
    .footer-nav {
      margin: 0;
      display: inline-block;
      li {
        display: block;
        padding: 4px 0;
        a {
          color: #4a4a4a;
          font-size: 14px; } } }
    @include r($mobileL) {
      text-align: center; } } }


.social-block {
  margin: 0;
  .social-block-item {
    display: inline-block;
    margin-right: 15px;
    .social-block-icon {
      text-decoration: none; }
    .fa {
      color: $gray-dark;
      @include transition(all .3s ease);
      &:hover {
        transform: scale(1.1); } } }
  .social-block-item:last-child {
    margin: 0; }
  @include r($mobileL) {
    text-align: center; } }


.sub-footer-block {
  width: 100%;
  border-top: solid 1px #e2e2e2;
  padding-top: 17px;
  padding-bottom: 16px;
  @include clr;
  .sub-footer-left-title {
    float: left;
    color: #777777;
    @include r($mobileL) {
      width: 100%; } }
  .sub-footer-right-title {
    float: right;
    color: #777777;
    @include r($mobileL) {
      width: 100%; } }
  @include r($mobileL) {
    text-align: center; } }
