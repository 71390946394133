$banner-padding-big: 50px;
$banner-padding-mid: 30px;
$banner-padding-sm: 15px;
$banner-padding-xs: 7px;

.events-section {
    margin-top: 110px;
    margin-bottom: 15px;

    @include r($tablet) {
        margin-top: 70px; } }



.event-banners {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 50%;
        padding: $banner-padding-sm;

        &--sm {
            width: 33.3333%; }

        @include r($mobileL) {
            width: 100%;
            padding: $banner-padding-xs $banner-padding-sm; } } }


.events-banner {
    display: block;
    position: relative;

    &__content {
        color: $white;
        padding: $banner-padding-big;

        @include r($tablet) {
            padding: $banner-padding-mid; } }

    &--lg {
        height: 500px;
        display: flex;
        align-items: flex-end;
        margin: 20px 15px 15px;

        .events-banner__content {
            max-width: 650px; }

        p {
            margin-bottom: 2rem; } }


    &--md {
        min-height: 300px;
        color: $white;

        &:hover {
            color: $white; } }


    &--sm {
        min-height: 200px;
        display: flex;
        align-items: flex-end;

        &:hover {
            p {
                display: block; } }

        .events-banner__content {
            padding: $banner-padding-mid;
            background: $black;
            width: 100%;

            @include r($tablet) {
                padding: $banner-padding-sm; } }

        h5 {
            margin: 0; }

        p {
            display: none;
            margin-top: 1rem; } }



    h1, h3 {
        font-weight: 100;
        margin-top: 0; } }




.event {
    margin-bottom: 3rem;

    &__image {
        width: 100%;
        display: block; }


    &__inner {
        display: flex;
        flex-wrap: wrap;
        background: $gray-light-5; }

    &__content {
        padding: 30px;
        width: 70%;

        @include r($tablet) {
            width: 60%; }

        @include r($mobileL) {
            width: 100%; } }


    &__contacts {
        background: $black;
        color: $white;
        padding: 30px;
        width: 30%;

        @include r($tablet) {
            width: 40%; }

        @include r($mobileL) {
            width: 100%; } }


    hr {
        border-top-color: $gray-light-1; } }

