// ******************************************************
// Helpers
// ******************************************************

// Padding
// *******************************************
.p-0{
  padding: 0;
}
@for $padding from 0 through 25 {
  .pt-#{$padding} {
    padding-top: $padding * 1px;
  }
}

@for $padding from 0 through 25 {
  .pb-#{$padding} {
    padding-bottom: $padding * 1px;
  }
}

@for $padding from 0 through 25 {
  .pl-#{$padding} {
    padding-left: $padding * 1px;
  }
}

@for $padding from 0 through 25 {
  .pr-#{$padding} {
    padding-right: $padding * 1px;
  }
}

// Margin
// *******************************************
.m0{
  margin: 0;
}
@for $margin from 0 through 25 {
  .mt-#{$margin} {
    margin-top: $margin * 1px;
  }
}

@for $margin from 0 through 25 {
  .mb-#{$margin} {
    margin-bottom: $margin * 1px;
  }
}

@for $margin from 0 through 25 {
  .ml-#{$margin} {
    margin-left: $margin * 1px;
  }
}

@for $margin from 0 through 25 {
  .mr-#{$margin} {
    margin-right: $margin * 1px;
  }
}


// z-depth
// *******************************************

.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}



// divider-with-text
// *******************************************
.divider-with-text {
  width: 100%;
  text-align: center;
  line-height: 1rem;
  margin: 2rem 0 2rem;
  position: relative;

  & > span {
    display: inline-block;
    padding: 0 1rem;
    background: $white;
    position: relative;
    z-index: 2;
    color: $gray-light;
  }

  &::after {
    content: '';
    display: block;
    @include vcenter;
    width: 80%;
    height: 1px;
    background: $gray-lighter;
    z-index: 1;
  }
}

// Display
// *******************************************
.display-block {
  display: block;
}


// float
// *******************************************
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.col-xs-12 {
  float: left;
}

// align
// *******************************************
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}



// divider
// *******************************************
.divider {

  &.divider-vertical {
    width: 1px;
    height: 1.4rem;
    margin: 0 30px;
    background: $gray-lighter;
  }
}

// chips
// *******************************************
.chips {
  padding-top: 15px;
  @include clr;

  .chip-item {
    text-transform: capitalize;
    float: left;
    margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    line-height: 34px;
    background: $gray-lighter;
    color: $primary-text-color;
    @include br(17px);
    @include transition(all, .3s, ease);

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background: darken($gray-lighter, 5%);
    }
  }
}

// more text
// *******************************************
.show-more {
  margin-left: 5px;
  font-weight: bold;
  color: $green !important;
}
