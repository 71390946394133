/*======================================
  Selectric
======================================*/
$main-color     : $gray-text;
$secondary-color: $white;
$bg-color       : #333745;
$text-color     : $gray-text;
$height         : 40px;
$spacing        : 10px;
$border-width   : 1px;
$inner-height   : 40px;

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  background: $bg-color;
  position: relative;

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    text-overflow: ellipsis;
    margin: 0 15px 0 0;
    font-size: 1rem;
    line-height: 36px;
    color: $text-color;
    height: $inner-height;
  }

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: $inner-height;
    height: $inner-height;
    color: $secondary-color;
    text-align: center;
    font: 0/0 a;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: $secondary-color;
      border-bottom: none;
    }
  }
}

.selectric-focus .selectric {
  background: darken($bg-color, 10%);
}

.selectric-hover .selectric {
  background: darken($bg-color, 5%);

  .button {
    color: darken($secondary-color, 10%);

    &:after {
      border-top-color: darken($secondary-color, 10%);
    }
  }
}

.selectric-open {
  z-index: 9999;
  background: darken($color-primary-darker, 3%);

  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: darken($color-primary-darker, 3%);
  z-index: -1;
  box-shadow: 0 0 10px -6px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px;
  }

  li {
    display: block;
    padding: 10px;
    color: #FFF;
    cursor: pointer;

    &.selected {
      background: $color-primary-darker;
      color: #FFF;
    }

    &.highlighted {
      background: $color-primary-darker;
      color: #FFF;
    }

    &:hover {
      background: $color-primary-darker;
      color: #FFF;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #FFF !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #FFF;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}
