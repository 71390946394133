// ******************************************************
// site styles
// ******************************************************

html {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4em !important;
  font-family: "Circe";
  @include r($tablet) {
    font-size: .9rem !important; }
  @include r($mobileL) {
    font-size: .8rem !important; }
  img {
    max-width: 100%; } }


body {
  font-size: 1rem;
  font-weight: normal;
  font-family: "Circe", Helvetica, Arial, sans-serif !important; }


h1 {
  font-size: 2.2rem;
  line-height: 1.4em;
  font-weight: 700; }
h2 {
  font-size: 2rem;
  line-height: 1.4em;
  font-weight: 600; }
h3 {
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: 500; }
h4 {
  font-size: 1.6rem;
  line-height: 1.4em;
  font-weight: 500; }
h5 {
  font-size: 1.2rem;
  line-height: 1.4em;
  font-weight: 500; }
h6 {
  font-size: 0.9rem;
  line-height: 1.4em;
  font-weight: 400; }
p, label {
  font-size: 1rem;
  line-height: 1.4em;
  font-weight: 400; }



