// ******************************************************
// Colors
// ******************************************************
// Standard colors
// *******************************************
$white: #ffffff;
$black: #000000;
$green: #71bd45;
$blue-dark: #002f6a;
$blue-light: #f7f8f8;
$gray-color: #929497;
$gray-yellow: #f2f8f3;
// Primary colors
// *******************************************
$color-primary: #113B47;
$color-primary-dark: #113B47;
$color-primary-darker: #12181f;
$color-primary-light: #1B5A6B;
$color-accent: #78D2EC;
$color-accent-dark: #209ABD;
// Alert colors
// *******************************************
$color-success: #5cb85c !default;
$color-info: #5bc0de !default;
$color-warning: #f0ad4e !default;
$color-danger: #d9534f !default;
// Gray
// *******************************************
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default;
// #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$gray-light-1: #d2d2d2;
$gray-light-2: #e2e2e2;
$gray-light-3: #bfc1c3;
$gray-light-4: #f8f8f8;
$gray-light-5: #f4f4f4;

$gray-middle: #fafafa;
// Text color
// *******************************************
$primary-text-color: #39393a;
$text-color: #231f20;
$gray-text: #969696;
// ******************************************************
// Media queries
// ******************************************************
$mobileS: 320;
$mobileM: 375;
$mobileL: 425;
$tablet: 768;
$laptop: 1024;
$laptopL: 1440;
$mobileMinS: 320;
$mobileMinM: 375;
$mobileMinL: 425;
$tabletMin: 769;
$laptopMin: 1024;
$laptopMinL: 1440;
// ******************************************************
// Padding & Margin
// ******************************************************
$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;
$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;
$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;
$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;
// ******************************************************
// Buttons
// ******************************************************
$btn-font-weight: normal !default;
$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;
$btn-primary-color: #fff !default;
$btn-primary-bg: $color-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;
$btn-success-color: #fff !default;
$btn-success-bg: $color-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;
$btn-info-color: #fff !default;
$btn-info-bg: $color-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;
$btn-warning-color: #fff !default;
$btn-warning-bg: $color-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;
$btn-danger-color: #fff !default;
$btn-danger-bg: $color-danger !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;
$btn-link-disabled-color: $gray-light !default;
// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default;
// ******************************************************
// Box Shadow
// ******************************************************
