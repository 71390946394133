// ******************************************************
// Index Page
// ******************************************************
.main-section {
  width: 100%; }

.fa {
  line-height: inherit !important; }

.main-section-content {
  width: 100%;
  height: 100vh;
  position: relative;
  display: block;
  &.inner-page-banner {
    height: 60vh; }
  .main-text-block {
    @include vcenter;
    z-index: 10;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    .main-slider-title {
      font-size: 64px;
      font-weight: 200;
      margin-top: 0;
      @include r($tablet) {
        font-size: 3rem; } }
    .main-slider-text {
      font-size: 20px;
      font-weight: 200;
      margin: 0 auto;
      max-width: 600px;
      @include r($tablet) {
        font-size: 1.4rem; } } }
  &.text-left {
    text-align: left; }
  &.text-right {
    text-align: right; }
  &.dark-section {
    color: $white; }
  &.light-section {
    color: $black; } }


// ******************************************************
// brands
// ******************************************************
.brands {
  width: 100%;
  padding-top: 28px;
  padding-bottom: 26px;
  background-color: #f8f8f8;
  text-align: center;
  .brands-item {
    padding-right: 27px;
    text-decoration: none; }
  .brands-item:last-child {
    padding: 0; } }

// ******************************************************
// category-filter
// ******************************************************
.category-filter {

  .filter-button {
    display: none; }

  @include r($tablet) {
    position: fixed;
    z-index: 600;
    top: 5vh;
    height: 90vh;
    left: -310px;
    padding: 0;
    @include transition(all .3s ease);
    .category-filter-inner {
      background: $white;
      position: relative;
      height: 100%;
      overflow-y: scroll;
      border: 2px solid $color-primary;
      padding: 0 15px;
      width: 260px; }
    .filter-button {
      background: $color-primary-darker;
      color: $white;
      position: absolute;
      top: 0;
      right: -50px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      display: block;
      text-align: center; }
    &.scrolled {
      left: -260px;
      &.active {
        left: 0;
        .filter-button {
          background: $color-primary; } } } } }

.filter-block {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: solid 1px $gray-light-2;
  .filter-block-title {
    font-size: 16px;
    margin-bottom: 20px; }
  .SumoSelect {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0; } }
  .chips {
    margin-top: -5px;
    margin-bottom: -5px; } }

// ******************************************************
// chips
// ******************************************************
.chips {
  width: 100%;
  margin: 0;
  padding-top: 0;
  @include clr;
  li {
    float: left;
    margin: 5px 10px 5px 0;
    .chips-item {
      margin: 0;
      input[type="checkbox"] {
        display: none; }
      .chips-title {
        background-color: $gray-light-5;
        color: #000;
        padding: 5px 10px;
        cursor: pointer;
        display: block;
        @include transition(all .3s ease);
        @include minr($tablet) {
          &:hover {
            background-color: $gray-light-3;
            color: #fff; } } }
      input[type="checkbox"]:checked + .chips-title {
        background-color: $color-primary-darker;
        color: #fff; }
      input[type="checkbox"]:focus + .chips-title {
        background-color: $gray-light-5;
        color: $color-primary-darker; }
      input[type="checkbox"]:active + .chips-title {
        background-color: $gray-light-5;
        color: $color-primary-darker; } } } }


// ******************************************************
// custom content
// ******************************************************
.custom-content {
  width: 100%;
  height: 400px;
  // left-content
  .left-content {
    float: left;
    width: 45%;
    height: 100%;
    position: relative;
    display: block;
    .left-content-text-block {
      position: absolute;
      bottom: 30px;
      right: 30px;
      text-align: right;
      width: 368px;
      max-width: calc(100% - 60px);
      z-index: 10;
      .left-content-text-block-title {
        color: #ffffff;
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 20px;
        margin-top: 0; }
      p {
        color: #ffffff;
        font-size: 14px;
        margin: 0; } }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 0;
      @include transition(all .3s ease); }
    &:hover {
      &:after {
        opacity: .1; } }
    @include r($laptop) {
      width: 40%; }
    @include r($tablet) {
      width: 50%; }
    @include r($mobileL) {
      width: 100%; } }
  // center-content
  .center-content {
    float: left;
    width: 26%;
    height: 100%;
    // display TOP
    .center-content-top {
      background-color: #f4f4f4;
      height: 200px;
      position: relative;
      display: block;
      @include clr;
      .text-right {
        position: absolute;
        bottom: 30px;
        right: 30px;
        max-width: 166px;
        text-align: right;
        h3 {
          font-size: 20px;
          margin-top: 0;
          color: #000;
          margin-bottom: 23px; }
        p {
          font-size: 14px;
          color: $gray-text;
          margin-bottom: 0; } } }
    // display BOTTOM
    .center-content-bottom {
      background-color: #f4f4f4;
      height: 200px;
      position: relative;
      z-index: 20;
      display: block;
      @include clr;
      .text-left {
        position: absolute;
        top: 30px;
        left: 30px;
        max-width: 166px;
        text-align: left;
        z-index: 100;
        h3 {
          font-size: 20px;
          margin-top: 0;
          color: #000;
          margin-bottom: 23px; }
        p {
          font-size: 14px;
          color: $gray-text; } } }
    @include r($laptop) {
      width: 32%; }
    @include r($tablet) {
      width: 50%; }
    @include r($mobileL) {
      width: 100%; } }
  // right-content
  .right-content {
    float: left;
    width: 29%;
    height: 100%;
    position: relative;
    display: block;
    .right-content-text-block {
      position: absolute;
      top: 30px;
      left: 30px;
      text-align: left;
      width: 251px;
      max-width: calc(100% - 60px);
      z-index: 10;
      .right-content-text-block-title {
        color: #ffffff;
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 20px;
        margin-top: 0; }
      p {
        color: #ffffff;
        font-size: 14px; } }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 0;
      @include transition(all .3s ease); }
    &:hover {
      &:after {
        opacity: .1; } }
    @include r($laptop) {
      width: 28%; }
    @include r($tablet) {
      width: 100%; }
    @include r($mobileL) {
      width: 100%; } } }

// ******************************************************
// mobiles
// ******************************************************
.mobiles-section {
  width: 100%;
  padding: 60px 0;
  clear: both;
  overflow: hidden; }


.mobile-slider-filter {
  display: block;
  margin-bottom: 50px;
  line-height: 56px;
  clear: both;
  overflow: hidden;
  text-align: center;
  .btn {
    display: inline-block;
    padding: 17px 60px;
    color: #4a4a4a;
    border: solid 1px $gray-light-2;
    cursor: pointer;
    @include r($mobileL) {
      padding: 17px 25px; }
    @include r($mobileS) {
      padding: 17px 20px; }
    &:hover, &.active {
      color: $white;
      background: $color-primary-darker; } }
  .new-arrivals-btn {
    margin-right: 15px;
    @include r($mobileL) {
      margin-right: 10px; } }
  .coming-soon-btn {
    margin-left: 15px;
    @include r($mobileL) {
      margin-left: 10px; } } }



#mobile-items-slider {
  .slick-list {
    padding-top: 30px; }
  .slick-dots {
    bottom: 30px !important; }
  .mobile-item-block {
    .mobile-item {
      &:hover {
        img {
          @include scale(1.05); } } } }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background: $white; } }

.mobile-item-block {
  display: block;
  border-right: 1px solid $gray-light-2;
  margin-bottom: 60px;
  padding: 0;
  position: relative;
  .item-hovered {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include bs(0 0 16px 0 rgba(0, 0, 0, 0.2));
    display: none;
    &.active {
      display: block; } }
  .mobile-item {
    text-align: center;
    position: relative;
    padding: 30px;
    @include r($tablet) {
      padding: 25px; }
    @include r($mobileL) {
      padding: 15px; }
    .mobile-item-img {
      width: 100%;
      padding-bottom: 25px;
      @include r($tablet) {
        padding-bottom: 20px; }
      img {
        max-width: 90%;
        width: 150px;
        display: block;
        margin: 0 auto;
        z-index: 50;
        @include transition(all .3s ease); } }
    // mobile info
    .mobile-info {
      .mobile-title {
        font-size: 1.3rem;
        color: $primary-text-color;
        margin: 0;
        font-weight: normal;
        @include r($mobileL) {
          font-size: 1rem; } }
      .mobile-info-subtitle {
        color: $gray-color;
        margin: 0;
        font-size: 1rem;
        margin-bottom: 20px;
        @include r($mobileL) {
          display: none; } }
      .mob-price {
        margin-bottom: 0;
        font-size: 1.3rem;
        line-height: 1.29;
        text-align: center;
        font-weight: 600;
        color: $primary-text-color;
        @include r($tablet) {
          margin-top: 10px; }
        @include r($mobileL) {
          margin-top: 5px;
          font-size: 1.1rem; } } } }
  .mobile-item-description {
    padding: 0 30px 30px;
    z-index: 400;
    display: none;
    position: absolute;
    background-color: $white;
    width: 100%;
    .product-detail-second {
      padding-top: 30px;
      border-top: 1px solid $gray-light-2;
      .product-detail-second-item {
        display: block;
        color: $primary-text-color;
        margin-bottom: 5px;
        & > span {
          color: #cccccc; } } } }
  &:hover {
    text-decoration: none;
    .mobile-item-description {
      @include minr($tabletMin) {
        display: block; } } }
  &:focus {
    outline: none;
    text-decoration: none;
    color: none; } }

// Select color
.select-color {
  margin-bottom: 25px;
  height: 12px;
  line-height: 12px;
  @include r($tablet) {
    margin-bottom: 15px; }
  @include r($mobileL) {
    display: none; }
  .select-color-item {
    display: inline-block;
    margin-right: 10px;
    .color-dot {
      border: 1px solid $gray-light-3;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $gray; } }
  .select-color-item:last-child {
    margin-right: 0; } }

.see-all {
  text-align: center; }

// radio label

.radio-label {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 0;
  margin-right: 5px; }

// ******************************************************
// Tabs
// ******************************************************

.tabs {
  .tabs-content {
    .tab-item {
      display: none;
      &.active {
        display: block; } } } }

// ******************************************************
// stay in touch
// ******************************************************


.stay-in-touch {
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #12181f;
  .stay-in-touch-text {
    text-align: right;
    h3 {
      margin: 0;
      font-size: 26px;
      color: #ffffff;
      font-weight: 900; }
    p {
      margin: 0;
      color: #a7a7a7;
      font-size: 16px; }
    @include r($mobileL) {
      text-align: center;
      margin-bottom: 15px; } }
  .subscribe-form {
    width: 570px;
    max-width: 100%;
    margin: 0;
    position: relative;
    .subscribe-input {
      width: 400px;
      max-width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 55px;
      padding-left: 16px;
      padding-right: 16px;
      background: none;
      border: 1px solid $white;
      outline: none;
      color: #ffffff;
      @include r($tablet) {
        width: 240px; }
      @include r($mobileL) {
        display: block;
        width: 100%;
        margin-bottom: 13px; } }
    .subscribe-btn {
      max-width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 55px;
      width: 170px;
      padding: 0;
      border: none;
      background-color: $white;
      font-size: 20px;
      &:active, &:focus {
        border: none;
        outline: none; }
      &:hover {
        background-color: $gray-light-3; }
      @include r($mobileL) {
        display: block;
        width: 100%;
        position: relative; } } } }


// ******************************************************
// 	CATEGORY PAGE
// ******************************************************

.page-top-section {
  width: 100%;
  height: 400px;
  position: relative;
  .page-top-section-content {
    position: absolute;
    bottom: 40px;
    z-index: 10;
    .page-top-subtitle {
      margin-bottom: 0;
      font-size: 32px;
      font-weight: 300;
      line-height: 1.75;
      @include r($tablet) {
        font-size: 2.2rem; } }
    .page-top-title {
      font-size: 54px;
      font-weight: 200;
      line-height: 1.04;
      margin: 0;
      @include r($tablet) {
        font-size: 3rem; } } }
  &.dark-section {
    color: $white; }
  &.light-section {
    color: $black; } }

.category-page-body {
  margin: 50px 0; }


.pagination-block {
  display: block;
  margin-top: 50px;
  & > ul {
    margin: 0 auto;
    text-align: center;
    @include clr;
    li {
      display: inline-block;
      margin-right: 10px;
      text-align: center;
      a {
        border: none;
        font-size: 15px;
        color: $gray-text;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: block;
        border-radius: 50%;
        @include transition(all .3s ease);
        @include r($tablet) {
          width: 30px;
          height: 30px;
          line-height: 30px;
          font-size: 11px; }
        &:hover, &.active {
          background-color: $color-primary-darker;
          text-decoration: none;
          color: #ffffff; } }
      &.pagination-dots {
        a:hover {
          background-color: transparent;
          text-decoration: none;
          color: $gray-text; } }
      @include r($mobileL) {
        margin-right: 22px; }
      @include r($mobileM) {
        margin-right: 12px; } }
    .left-angle, .right-angle {
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #e6e6e6;
      @include transition(all .3s ease); }
    .right-angle {
      margin-right: 0; }
    @include r($laptop) {
      width: 100%; }
    @include r($tablet) {
      width: 100%; } } }

.news-pagination {
  margin: 60px 0;
  @include r($mobileL) {
    margin-bottom: 20px; }
  .news-pagination-page {
    width: 100% !important;
    margin: 0 !important;
    .right-angle, .left-angle {
      &.active {
        background-color: #002f6a !important; } }
    li {
      margin-bottom: 20px;
      a {
        &:hover, &.active {
          background-color: #002f6a !important; } } } } }

.product-page-mobile-items {
  margin-bottom: 50px;
  position: relative;
  @include clr;
  &::before {
    content: '';
    height: 100%;
    width: 1px;
    background: $white;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    z-index: 10; }
  @include clr;
  .mobile-item-block {
    margin-bottom: 0;
    border-bottom: 1px solid $gray-light-2;
    min-height: 352px;
    @include r($tablet) {
      min-height: 330px; }
    @include r($mobileL) {
      min-height: 252px; }
    @include r($mobileM) {
      min-height: 229px; }
    @include r($mobileS) {
      min-height: 201px; } } }

.category-page-news {
  width: 100%; }

.category-page-news-left-block {
  width: 100%;
  height: 450px;
  margin-bottom: 30px;
  display: block;
  position: relative;
  text-decoration: none;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 0; } }

.category-page-news-right-block {
  width: 100%;
  .category-page-news-left-item {
    width: 100%;
    height: 210px;
    margin-bottom: 30px;
    display: block;
    position: relative;
    text-decoration: none;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 0; } }
  .category-page-news-right-item {
    width: 100%;
    height: 210px;
    margin-bottom: 30px;
    display: block;
    position: relative;
    text-decoration: none; } }

.category-page-news-content {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 30px 30px;
  max-width: 100%;
  z-index: 20;
  .category-page-news-content-title {
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 15px; }
  .category-page-news-content-text {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
    width: 340px;
    max-width: 100%; } }

// ******************************************************
// 	PRODUCT PAGE
// ******************************************************


.product-page-main {
  height: 100vh;
  position: relative;
  .product-page-content {
    @include vcenter;
    width: 100%;
    z-index: 20;
    .product-page-content-title {
      max-width: 100%;
      margin: 0 auto;
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 64px;
      font-weight: 300;
      text-align: center;
      color: #ffffff; }
    .product-page-content-text {
      width: 463px;
      max-width: 100%;
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      color: #ffffff;
      margin: 0 auto; } }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 0; } }

.product-info-section {
  background-color: #f5f5f5;
  padding: 55px 0; }

.product-info-img {
  text-align: center;
  img {
    display: none;
    margin: 0 auto;
    @include r($tablet) {
      margin-bottom: 30px; }
    @include r($mobileL) {
      width: 280px; }
    @include r($mobileM) {
      width: 200px; }
    @include r($mobileS) {
      width: 180px; }
    &.active-color {
      display: block; } } }

.product-info-text {
  @include r($mobileL) {
    text-align: center; }
  .mobile-title-big {
    font-size: 48px;
    font-weight: 200;
    margin-top: 0;
    color: $black;
    @include r($mobileL) {
      text-align: center;
      font-size: 32px; } }
  .product-detail {
    margin-top: 30px;
    margin-bottom: 27px;
    text-align: left;
    .product-detail-item {
      color: #000;
      @include clr;
      & > span:first-child {
        float: left;
        width: 35%;
        color: $gray-text;
        padding-right: 10px; }
      & > .detail-text {
        width: 65%;
        float: left; } } }
  .price {
    font-size: 30px;
    font-weight: 300;
    color: #4a4a4a;
    margin-bottom: 0;
    .price-title {
      font-size: 16px;
      display: block;
      color: #4a4a4a; } }
  .product-characteristics {
    width: 370px;
    max-width: 100%;
    padding: 9px 0;
    margin-top: 27px;
    margin-bottom: 40px;
    border-top: 1px solid #c4c4c4;
    @include r($mobileL) {
      margin-bottom: 20px;
      margin-top: 20px; }
    @include clr;
    li {
      float: left;
      padding-right: 15px;
      font-size: 14px;
      color: #4a4a4a;
      margin-bottom: 9px; } } }


.full-product-specifications {
  padding: 60px 0;
  display: none;
  .full-product-specifications-title {
    margin: 0;
    text-align: center;
    font-size: 36px;
    color: $gray-text;
    font-weight: 500; }
  // specs content
  .specs-content {
    padding: 20px 0;
    .specs-blocks {
      margin-bottom: 3rem;
      .specs-block-img {
        width: 60px;
        height: 60px;
        margin-bottom: 30px;
        img {
          width: 100%; }
        @include r($mobileL) {
          margin: 0 auto; } }
      .specs-block-text {
        margin-bottom: 8px;
        .specs-block-text-title {
          color: $gray-light;
          font-weight: 600; } }
      p {
        margin-bottom: 3px;
        color: $gray-light; } } } }

// ***************see all block
.see-all-block {
  display: block;
  text-align: center; }

// *************** product info second

#pinContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh; }

.product-slide {
  width: 100%;
  overflow: hidden;
  .product-slide-content {
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    .content-text {
      width: 50%;
      flex: none;
      .content-title {
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 48px;
        font-weight: 300;
        @include r($tablet) {
          font-size: 2em; }
        @include r($mobileL) {
          font-size: 1.6em; } }
      .content-subtitle {
        max-width: 100%;
        font-size: 20px;
        font-weight: 200;
        @include r($tablet) {
          font-size: 1.2em; } } }
    .content-img > img {
      display: block;
      margin: 0 auto; } }

  &.light-slide {
    color: $black; }

  &.dark-slide {
    color: $white; }

  &.content-left {
    .product-slide-content {
      @include flex-direction(row);
      @include r($tablet) {
        @include flex-direction(column);
        @include justify-content(flex-start); }
      .content-text {
        text-align: right;
        padding: 3rem;
        @include r($tablet) {
          width: 80%;
          text-align: center;
          padding: 3rem 1.5rem; }
        @include r($mobileL) {
          width: 100%; } }
      .content-img {
        width: 50%;
        flex: none;
        margin: 0 auto;
        @include r($tablet) {
          width: 70%; } } } }

  &.content-right {
    .product-slide-content {
      @include flex-direction(row-reverse);
      @include r($tablet) {
        @include flex-direction(column);
        @include justify-content(flex-start); }
      .content-text {
        text-align: left;
        padding: 3rem;
        @include r($tablet) {
          width: 80%;
          text-align: center;
          padding: 3rem 1.5rem; }
        @include r($mobileL) {
          width: 100%; } }
      .content-img {
        width: 50%;
        flex: none;
        margin: 0 auto;
        @include r($tablet) {
          width: 70%; } } } }

  &.content-top {
    .product-slide-content {
      @include flex-direction(column);
      @include justify-content(flex-start);
      @include r($mobileL) {
        @include justify-content(center); }
      .content-text {
        width: 80%;
        max-width: 800px;
        padding: 3rem 1.5rem;
        text-align: center;
        @include r($mobileL) {
          height: 50%;
          width: 100%; } }
      .content-img {} } }


  &.content-bottom {
    .product-slide-content {
      @include flex-direction(column-reverse);
      @include justify-content(flex-start);
      @include r($mobileL) {
        @include justify-content(center); }
      .content-text {
        width: 80%;
        max-width: 800px;
        padding: 3rem 1.5rem;
        text-align: center;
        @include r($mobileL) {
          height: 50%;
          width: 100%; } }
      .content-img {} } } }


// *************** related products
.related-products {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 60px;
  .related-products-title {
    margin: 0;
    font-size: 32px;
    line-height: 0.84;
    text-align: center;
    color: #000000; }
  .related-products-slider {
    margin-top: 60px;
    .related-slider-item {
      display: block;
      cursor: pointer;
      &:focus {
        border: none;
        outline: none; }
      &:hover img {
        @include scale(1.05); }
      .related-slider-item-img {
        width: 100%;
        height: 120px;
        margin-bottom: 17px;
        padding: 5px 0;
        & > img {
          height: 100%;
          margin: 0 auto;
          @include transition(all .3s ease); } }
      .related-slider-item-content {
        text-align: center;
        .related-slider-item-title {
          margin-top: 0;
          margin-bottom: 6px;
          font-size: 16px;
          line-height: 1.38;
          color: #000000; }
        .related-slider-item-text {
          margin: 0;
          font-size: 14px;
          line-height: 1.33;
          color: #808080; } } } } }

// ******************************************************
// 	BRAND PAGE
// ******************************************************
.brand-page-main {
  width: 100%;
  height: 40vh;
  position: relative;
  .logo-mobile {
    position: absolute;
    bottom: 30px;
    z-index: 20; }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2); } }

.gallery-slider {
  padding: 50px 0; }

.gallery-product-slider {
  margin-top: 60px;
  .slic-list {
    margin-top: 50px; }
  .gallery-slider-item {
    .gallery-slider-item-img {
      height: 120px;
      margin-bottom: 17px;
      padding: 5px 0;
      & > img {
        height: 100%;
        margin: 0 auto; } }
    .gallery-slider-item-content {
      text-align: center;
      .gallery-slider-item-title {
        margin-top: 0;
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 1.38;
        color: #000000; }
      .gallery-slider-item-text {
        margin: 0;
        font-size: 15px;
        line-height: 1.33;
        color: #808080; } } } }

.brand-info-block {
  width: 100%;
  margin-top: 65px;
  position: relative;
  background-color: #f8f8f8;
  .brand-info-block-content {
    padding: 50px 0;
    max-width: 100%;
    .brand-info-block-content-title {
      font-size: 36px;
      line-height: 1.22;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 25px;
      color: #002f6a; }
    .brand-info-block-content-text {
      margin: 0;
      color: #000000;
      font-size: 17px;
      line-height: 1.53;
      font-weight: 300; } }
  .brand-info-block-img {
    width: 45%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    @include r($mobileL) {
      width: 100%;
      height: 200px;
      position: relative; } } }

// ******************************************************
// 	News PAGE
// ******************************************************
.news-section {
  margin-top: 110px; }

.news-main {
  float: left;
  margin-top: 30px;
  .news-main-head {
    width: 100%;
    position: relative;
    padding: 40px 0;
    min-height: 300px;
    @include r($tablet) {
      margin-bottom: 0; }
    .news-main-head-content {
      position: relative;
      padding-left: 30px;
      width: 441px;
      max-width: 100%;
      z-index: 20;
      @include r($tablet) {
        padding-left: 20px;
        padding-right: 20px;
        bottom: 20px; }
      h1 {
        margin-top: 0;
        color: #ffffff; }
      p {
        color: #ffffff;
        margin-top: 0;
        margin-bottom: 34px; } }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2); } }
  // news blocks
  .news-blocks {
    width: 100%;
    @include clr;
    // news item
    .news-item {
      margin-top: 50px;
      .news-content {
        .news-content-item-title {
          font-size: 21px;
          color: #002f6a; }
        .news-short-text {
          font-size: 14px;
          color: #000000; }
        span {
          font-size: 12px;
          color: #495c74; } }
      .news-img {
        width: 100%;
        height: 200px;
        position: relative;
        .date-news {
          position: absolute;
          top: 0;
          left: 0;
          padding: 5px 11px;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.6); } }
      @include r($laptop) {
        width: 50%; }
      @include r($tablet) {
        width: 50%; }
      @include r($mobileL) {
        width: 100%; } } }
  @include r($mobileL) {
    width: 100%; } }

.news-sidebar {
  float: right;
  margin-top: 30px;
  .news-content-short {
    padding: 30px 0;
    border-top: 2px solid gray;
    h5 {
      margin-top: 0; } }
  .news-content-short:first-child {
    border: none;
    padding-top: 0; }
  .empty-block {
    width: 100%;
    height: 270px;
    background-color: #f5f5f5;
    border: solid 1px #979797; }
  @include r($mobileL) {
    width: 100%;
    margin-bottom: 30px; } }

// ******************************************************
// 	news Inner page
// ******************************************************
.news-inner {
  margin-top: 30px;
  .news-inner-head {
    margin-bottom: 12px;
    .news-inner-head-title {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 32px;
      font-weight: 300;
      color: #002f6a; }
    .news-inner-head-content {
      display: block;
      @include clr;
      .news-inner-avtor-name {
        margin: 0;
        font-size: 14px;
        color: #495c74;
        float: left;
        max-width: 50%; }
      .news-social-block {
        float: right; } } }
  .news-inner-content {
    padding: 30px 0;
    border-top: 1px solid $gray-light-2;
    border-bottom: 1px solid $gray-light-2; } }

.news-inner-pagination {
  width: 100%;
  padding: 30px 0;
  @include clr;
  .little-news-block {
    width: 50%;
    float: left;
    .little-news-block-item {
      float: left;
      width: 270px;
      max-width: 80%;
      @include r($laptop) {
        margin-top: 20px; } } } }

.news-pag-btn {
  display: inline-block;
  background: none;
  background-color: #f4f4f4;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 300;
  color: #4a4a4a;
  cursor: pointer;
  @include transition(all .3s ease);
  float: left;
  margin-right: 30px;
  &:hover {
    text-decoration: none;
    background-color: #000;
    color: #fff; } }

// ******************************************************
// 	About Us Page
// ******************************************************
.page-top-section {
  width: 100%;
  height: 400px;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3); } }

.about-us-body {
  width: 100%;
  margin-top: 65px;
  position: relative;
  background-color: #f8f8f8;
  .about-us-content {
    padding: 50px 0;
    .about-us-content-title {
      font-size: 36px;
      line-height: 1.22;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 25px;
      color: #002f6a; }
    .about-us-content-text {
      margin: 0;
      color: #000000;
      font-size: 17px;
      line-height: 1.53;
      font-weight: 300; } }
  .about-us-img {
    width: 45%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    img {
      max-width: 100%;
      height: 100%; }
    @include r($mobileL) {
      width: 100%;
      height: 200px;
      position: relative; } } }

// ******************************************************
// 	corporate-sales Page
// ******************************************************
.corporate-sales-content {
  width: 100%;
  padding: 70px 0;
  .corporate-sales-content-item {
    text-align: center;
    .corporate-sales-content-item-title {
      width: 567px;
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 100%;
      font-size: 36px;
      text-align: center;
      line-height: 1.22; }
    .corporate-sales-content-item-text {
      width: 756px;
      max-width: 100%;
      font-size: 17px;
      font-weight: 300;
      line-height: 1.53;
      text-align: center;
      color: #000000;
      margin: 0 auto; } } }

// form
.form {
  width: 100%;
  background-color: $gray-middle; }

.form-block {
  width: 50%;
  padding: 50px 0;
  margin: 0 auto;
  // form header
  .form-header {
    display: block;
    margin-bottom: 40px;
    .form-header-title {
      margin: 0;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.57;
      color: #2661a5; }
    .form-header-sub-title {
      margin: 0;
      font-size: 19px;
      font-weight: 300;
      line-height: 1.37;
      color: #4a4a4a; } }
  // form body
  .form-body {
    display: block;
    margin-bottom: 20px;
    input {
      display: block;
      width: 100%;
      margin-bottom: 20px; }
    textarea {
      min-height: 120px;
      max-height: 100%;
      width: 100%;
      font-size: 16px;
      display: block;
      border: solid 1px #bdcde2;
      padding-top: 20px;
      padding-left: 22px;
      color: #000; } }
  // form footer
  .form-footer {
    display: block;
    @include clr;
    .form-footer-text {
      float: left;
      color: red;
      margin: 0;
      line-height: 45px; }
    .form-footer-btn {
      cursor: pointer;
      border: none;
      padding: 0 30px;
      float: right;
      background-color: #00357e;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      @include transition(all .3s ease);
      &:hover {
        background-color: #12181f; } } }
  @include r($mobileL) {
    width: 80%; } }

// ******************************************************
// 	 Brand Slider
// ******************************************************
.brands-slider {
  width: 100%;
  margin: 30px 0;
  padding: 30px 0; }

.brands-slider-content, .stn-dots-slider, #mobile-items-slider {
  .slick-dots {
    position: relative;
    bottom: -25px;
    li {
      width: 25px;
      margin: 0;
      button {
        width: 25px;
        position: relative;
        &:before {
          width: 6px;
          @include vcenter;
          height: 6px;
          border: solid 1px #979797;
          background-color: transparent;
          border-radius: 50%; } } }
    li.slick-active {
      button {
        &:before {
          background-color: #000;
          width: 10px;
          height: 10px;
          border: none; } } } } }

// ******************************************************
// 	Contact Us
// ******************************************************
.contact-us-main {
  margin-top: 110px;
  margin-bottom: 70px; }

.map {
  width: 100%;
  height: 400px;
  background-color: $gray-light-2;
  margin-top: 30px; }

.contact-us {
  width: 100%;
  padding-top: 70px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 50px;
  background-color: #002f6a;
  @include clr;
  .contact-us-title {
    margin-top: 0;
    margin-bottom: 55px;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.11;
    color: #ffffff; }
  // numbers
  .contact-us-numbers {
    width: 33.333%;
    float: left;
    padding-bottom: 20px;
    padding-right: 15px;
    .contact-us-number-title {
      color: #fff;
      margin-top: 0;
      margin-bottom: 14px; }
    .contact-us-number-text {
      color: #fff; }
    @include r($mobileL) {
      width: 100%;
      padding-right: 0; } }
  // adress
  .contact-us-adress {
    width: 33.333%;
    float: left;
    padding-bottom: 20px;
    padding-right: 15px;
    .contact-us-adress-title {
      color: #fff;
      margin-top: 0;
      margin-bottom: 14px; }
    .contact-us-adress-text {
      color: #fff; }
    @include r($mobileL) {
      width: 100%;
      padding-right: 0; } }
  // index code
  .contact-us-index-code {
    width: 33.333%;
    float: left;
    padding-bottom: 20px;
    padding-right: 15px;
    .contact-us-index-code-title {
      color: #fff;
      margin-top: 0;
      margin-bottom: 14px; }
    .contact-us-index-code-text {
      color: #fff; }
    @include r($mobileL) {
      width: 100%;
      padding-right: 0; } }
  @include r($mobileL) {
    text-align: center; } }

.blue-dark {
  background-color: #002f6a; }

.black {
  background-color: #000; }

.red {
  background-color: red; }

.gray {
  background-color: #afafaf; }

.orange {
  background-color: #d47953; }

.brown {
  background-color: #e6bc7f; }


// ******************************************************
// Search Results
// ******************************************************

.search-result-block {
  width: 100%;
  margin-top: 150px; }

.search-label {
  width: 100%;
  height: 60px;
  margin: 0;
  position: relative;
  .search-input {
    width: 100%;
    border: none;
    background-color: #f8f8f8;
    padding: 0px 30px;
    line-height: 60px;
    font-size: 18px;
    color: #b4b4b4;
    &:focus {
      border: none; } }
  .search-btn {
    position: absolute;
    right: 30px;
    top: 50%;
    @include translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    .fa {
      color: #b4b4b4; }
    &:focus {
      border: none; } } }

.little-info-mob-blocks {
  margin-bottom: 40px;
  .little-info-mob {
    width: 100%;
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid #ebebeb;
    @include clr;
    .little-mob-info-img-block {
      width: 170px;
      height: 124px;
      float: left;
      img {
        max-width: 90%; } }
    .little-mob-info-content {
      float: left;
      margin-left: 30px;
      .little-mob-info-content-header-title {
        margin: 0;
        font-size: 16px;
        font-weight: 900; }
      .little-mob-info-content-bottom {
        margin-top: 35px;
        .little-mob-info-content-bottom-price {
          padding-top: 15px;
          border-top: 1px solid #d0d0d0;
          font-size: 17px;
          font-weight: 900;
          margin-right: 28px; }
        .order-link {
          position: relative;
          .fa {
            position: absolute;
            right: -10px;
            top: 50%;
            @include translateY(-50%); } } }
      @include r($tablet) {
        width: 70%; }
      @include r($mobileL) {
        width: 45%; }
      @include r($mobileM) {
        width: 30%; }
      @include r($mobileS) {
        width: 100%;
        margin: 0; } } }
  .little-info-mob:first-child {
    border: none;
    padding: 0; } }

.little-news-mob {
  margin-bottom: 40px;
  .little-news-mob-title {
    font-size: 17px;
    font-weight: 900;
    color: #000;
    margin-top: 0;
    margin-bottom: 11px; }
  .little-news-mob-text {
    font-size: 17px;
    color: #000;
    margin-top: 0;
    margin-bottom: 5px; }
  .little-news-mob-link {
    font-size: 17px; } }

// ******************************************************
// 	Overlay
// ******************************************************
.overlay-block {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  &.active-overlay {
    display: block;
    z-index: 600; } }
