// ******************************************************
// Popups
// ******************************************************

.popup-wrapper {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3000;
  visibility: hidden;
  opacity: 0;
  background: rgba($white, .8);
  @include transition(all .3s ease);
  &.opened-popup {
    visibility: visible;
    opacity: 1; }
  .popup-overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0; }
  .popup {
    width: 570px;
    background: $white;
    overflow: hidden;
    @include vcenter;
    @include br($border-radius-base);
    @extend .z-depth-5;
    @include r($mobileL) {
      width: 400px; }
    @include r($mobileM) {
      width: 300px; }
    // ******* popup-header
    .popup-header {
      .popup-title {
        font-size: 1.2rem;
        line-height: 3.5rem;
        text-align: center;
        color: $white;
        background: $green; }
      .popup-tabs-control {
        @include clr;
        li {
          text-transform: capitalize;
          float: left;
          width: 50%;
          font-size: 1.2rem;
          line-height: 3.5rem;
          text-align: center;
          background: $gray-lighter;
          color: $gray-light;
          cursor: pointer;
          @include transition(all .3s ease);
          &.active {
            background: $green;
            color: $white;
            &:hover {
              background: $green; } }
          &:hover {
            background: rgba($green, .5);
            color: $white; } } } }
    // ******* popup-body
    .popup-body {
      padding: 30px;
      padding-bottom: 15px;
      @include r($mobileL) {
        padding: 15px;
        padding-bottom: 0; } }
    // ******* popup-footer
    .popup-footer {} } }


// ******* .terms-block
.terms-block {
  margin-bottom: 1.5rem;
  text-align: center; }



// ******************************************************
// popup-book
// ******************************************************
.popup-book {
  overflow-y: scroll;
  // ******* popup
  .popup {
    top: 5vh;
    width: 90%;
    max-width: 980px;
    min-width: 300px;
    @include translate3d(-50%, 0, 0);
    margin-bottom: 5vh;
    .popup-body {
      padding: 50px;
      @include r($mobileL) {
        padding: 30px; }
      // ******* popup-book-info
      .popup-book-info {
        @include clr;
        // ******* book-image
        .book-image {
          width: 22%;
          float: left;
          @include r($laptop) {
            width: 18%; }
          @include r($mobileL) {
            width: 100%; }
          img {
            width: 100%;
            max-width: 200px;
            @extend .z-depth-3;
            @include r($mobileL) {
              display: block;
              width: 100px;
              margin: 0 auto 30px; } } }
        // ******* book-short-info
        .book-short-info {
          width: 78%;
          padding-left: 50px;
          padding-right: 0;
          float: left;
          @include r($laptop) {
            width: 82%; }
          @include r($mobileL) {
            width: 100%;
            padding-left: 0; }
          .book-title {
            font-size: 1.8rem;
            @include r($mobileL) {
              font-size: 1.4rem; } }
          .author-name {
            font-size: 1px rem;
            color: $gray-text;
            span {
              color: $green; } }
          .chips {
            padding-bottom: 0; }
          // ******* book-rating-and-social
          .book-rating-and-social {
            width: 100%;
            margin-bottom: 2rem;
            @include clr;
            .book-rating {
              width: 180px;
              float: left;
              margin-left: 4px;
              margin-right: 10px;
              @include r($mobileL) {
                width: 100%;
                margin-left: 0;
                margin-right: 0; } }
            .divider-vertical {
              float: left;
              @include r($mobileL) {
                display: none; } }
            .book-social {
              float: left;
              @include r($mobileL) {
                margin-top: 1rem;
                font-size: 1.8rem; }
              li {
                float: left;
                margin-right: 5px;
                a {
                  line-height: 1.4rem;
                  display: inline-block;
                  padding: 0 10px;
                  color: $gray-text;
                  &:hover {
                    color: $green; } } } } }
          // ******* book-control
          .book-control {
            .btn {
              margin-right: 1rem;
              margin-bottom: 1rem;
              &:last-child {
                margin-right: 0; } } } } }
      // ******* popup-book-tabs
      .tabs {
        margin-top: 50px;
        // ******* popup-tabs-control
        .popup-tabs-control {
          width: 100%;
          @include br($border-radius-base);
          overflow: hidden;
          li {
            width: 50%;
            float: left;
            text-align: center;
            line-height: 45px;
            background: $gray-lighter;
            color: $primary-text-color;
            font-size: 1.1rem;
            cursor: pointer;
            &.active {
              background: $green;
              color: $white; } } }
        // ******* popup-tabs-control
        .tabs-items {
          margin-top: 2.5rem;
          .annotation-title {
            text-align: center;
            margin-bottom: 20px; }
          .more-description {
            color: $primary-text-color; } } } } } }
