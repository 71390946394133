// ******************************************************
// Fonts
// ******************************************************

// ******* Material Icons
@include font('Circe', Circe-Regular, circe, normal, normal);

@include font('Circe', Circe-Light, circe, 400, normal);

@include font('Circe', Circe-ExtraLight, circe, 300, normal);

@include font('Circe', Circe-Bold, circe, 600, normal);

@include font('Circe', Circe-ExtraBold, circe, bold, normal);


