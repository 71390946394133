// ******************************************************
// Header
// ******************************************************

header {
  width: 100%;
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0; }

///////////////////////////
// top header
.top-header-block {
  position: relative;
  z-index: 100;
  height: 40px;
  background-color: $color-primary-darker;
  @include clr;
  // header navigation
  .top-header-nav {
    display: block;
    text-align: center;
    float: left;
    height: 40px;
    margin: 0;
    @include clr;
    li {
      float: left;
      line-height: 40px;
      height: 40px;
      padding: 0 14px;
      position: relative;
      a {
        font-size: 12px;
        text-transform: uppercase;
        color: $gray-text;
        &:hover {
          color: #fff;
          text-decoration: none; } }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        @include translateY(-50%);
        width: 2px;
        height: 7px;
        background-color: #4a4a4a; } }
    li:nth-child(1) {
      padding-left: 0; }
    li:last-child {
      &:after {
        display: none; } } }
  // language
  .language-select {
    float: right;
    width: 70px;
    .selectric {
      background-color: transparent; }
    .selectric-items li {
      padding-left: 25px;
      font-size: 1rem; } }
  // language-dropdown
  .language-dropdown {
    float: right;
    position: relative;
    text-transform: uppercase;
    & > a {
      color: $gray-text;
      font-size: 12px;
      padding: 0 14px;
      line-height: 40px;
      height: 40px;
      display: block;
      @include transition(all .3s ease);
      i {
        margin-left: 5px;
        @include transition(all .3s ease); }
      &:hover, &:focus {
        background: darken($color-primary-darker, 3%);
        text-decoration: none; }
      &.active {
        color: $white;
        & > i {
          @include rotate(180); } } }
    .dropdown-block {
      position: absolute;
      top: 100%;
      opacity: 0;
      visibility: hidden;
      background: darken($color-primary-darker, 3%);
      width: 100%;
      @include transition(all .3s ease);
      li {
        padding: 0 14px;
        line-height: 40px;
        height: 40px;
        color: $gray-color;
        @include transition(all .3s ease);
        cursor: pointer;
        border-bottom: 2px solid $color-primary-darker;
        &:hover {
          background: $color-primary-darker;
          color: $white; }
        &:last-child {
          border-bottom: 0; } }
      &.active {
        opacity: 1;
        visibility: visible; } } }
  // search-dropdown
  .search-dropdown {
    float: right;
    position: relative;
    text-transform: uppercase;
    & > a {
      color: $gray-text;
      font-size: 12px;
      padding: 0 14px;
      line-height: 40px;
      height: 40px;
      display: block;
      @include transition(all .3s ease);
      i {
        @include transition(all .3s ease); }
      &:hover, &:focus, &.active {
        background: darken($color-primary-darker, 3%);
        text-decoration: none;
        color: $white; } }
    .dropdown-block {
      position: absolute;
      top: 100%;
      right: 0;
      opacity: 0;
      visibility: hidden;
      background: darken($color-primary-darker, 3%);
      width: 400px;
      height: 70px;
      padding: 15px;
      @include transition(all .3s ease);
      @include r($tablet) {
        height: 50px;
        padding: 10px; }
      @include r($mobileL) {
        width: 340px; }
      @include r($mobileM) {
        width: 285px; }
      @include r($mobileS) {
        width: 230px; }
      .header-search-input {
        line-height: 20px;
        padding: 10px 15px;
        padding-right: 70px;
        border: none;
        outline: none;
        width: 100%;
        @include r($tablet) {
          line-height: 16px;
          padding: 7px 12px; } }
      .header-search-btn {
        background-color: transparent;
        text-transform: capitalize;
        text-align: center;
        font-size: 1rem;
        padding: 0;
        color: $black;
        border: none;
        outline: none;
        position: absolute;
        right: 15px;
        top: 50%;
        height: 40px;
        line-height: 40px;
        width: 70px;
        @include transition(all .3s ease);
        @include translateY(-50%);
        &:hover {
          color: $white;
          background: darken($color-primary-darker, 3%); }
        @include r($tablet) {
          height: 30px;
          right: 10px;
          line-height: 30px; } }
      &.active {
        opacity: 1;
        visibility: visible; } } } }

////////////////////////////
// main header
.main-header-block {
  position: relative;
  @include r($tablet) {
    background: rgba($color-primary-darker, .8); }
  .container {
    position: relative;
    @include r($tablet) {
      height: 50px; }
    // Logo
    .logo {
      position: absolute;
      left: 15px;
      top: 50%;
      width: 100px;
      height: 22px;
      @include translateY(-50%);
      .logo-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        @include transition(all .3s ease);
        opacity: 0;
        visibility: hidden;
        display: block;
        &.light-logo {
          opacity: 1;
          visibility: visible; } } }
    // Nav
    nav.nav {
      .main-header-nav {
        margin: 0;
        text-align: right;
        @include transition(all .3s ease);
        @include clr;
        li {
          display: inline-block;
          padding-left: 30px;
          padding-top: 23px;
          padding-bottom: 23px;
          a {
            line-height: 24px;
            color: $gray-light-5;
            font-size: 15px;
            position: relative;
            @include transition(all .3s ease);
            &::after {
              content: '';
              width: 0;
              height: 1px;
              background: $gray-light-5;
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              @include transition(all .3s ease); }
            &:hover, &.active {
              color: #000;
              text-decoration: none;
              &::after {
                width: 100%; } } } }
        @include r($tablet) {
          position: fixed;
          width: 260px;
          background-color: $color-primary-darker;
          right: -300px;
          height: 100vh;
          top: 0;
          z-index: 1000;
          & > li {
            width: 100%;
            text-align: center;
            padding: 15px 25px;
            padding-left: 0;
            a {
              height: 40px;
              line-height: 40px;
              display: block;
              width: 100%;
              &:hover {
                color: $white; } } } } }
      // nav-trigger
      .nav-trigger {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 15px;
        display: none;
        @include transition(all .3s ease);
        .nav-trigger-item {
          width: 24px;
          height: 1px;
          background-color: $white;
          display: block;
          position: absolute;
          top: 50%;
          right: 13px;
          &:after {
            content: '';
            position: absolute;
            width: 24px;
            bottom: 8px;
            height: 1px;
            background-color: $white; }
          &:before {
            content: '';
            position: absolute;
            width: 24px;
            height: 1px;
            top: 8px;
            background-color: $white; } }
        @include r($tablet) {
          display: block; } }
      // active-nav
      &.active-nav {
        .main-header-nav {
          right: 0; }
        .nav-trigger {
          position: fixed;
          right: 260px;
          background: $color-primary-darker; } } } }
  // light-theme
  &.light-theme {
    @include minr($tabletMin) {
      .logo-img {
        &.light-logo {
          opacity: 0;
          visibility: hidden; }
        &.dark-logo {
          opacity: 1;
          visibility: visible; } }
      .container .nav {
        .main-header-nav li a {
          color: $color-primary;
          &:hover, &.active {
            color: $color-primary-darker;
            &::after {
              background: $color-primary-darker; } } } } } }
  // dark-theme
  &.dark-theme {
    @include minr($tablet) {
      .logo-img {
        &.light-logo {
          opacity: 1;
          visibility: visible; }
        &.dark-logo {
          opacity: 0;
          visibility: hidden; } }
      .container .nav {
        .main-header-nav li a {
          color: $gray-light-5;
          &:hover, &.active {
            color: $white;
            &::after {
              background: $gray-light-5; } } } } } }
  // gray-theme
  &.gray-theme {
    background-color: $gray-light-4;
    @include r($tablet) {
      .container {
        // logo-img
        .logo-img {
          &.light-logo {
            opacity: 0;
            visibility: hidden; }
          &.dark-logo {
            opacity: 1;
            visibility: visible; } }
        .nav {
          & > .main-header-nav li a {
            color: $gray-light-5;
            &:hover, &.active {
              color: $white;
              &::after {
                background: $gray-light-5; } } }
          // nav-trigger
          & > .nav-trigger .nav-trigger-item {
            background-color: $color-primary-darker;
            &:after {
              background-color: $color-primary-darker; }
            &:before {
              background-color: $color-primary-darker; } }
          &.active-nav {
            & > .nav-trigger .nav-trigger-item {
              background-color: $white;
              &:after {
                background-color: $white; }
              &:before {
                background-color: $white; } } } } } } } }


