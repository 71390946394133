.dealers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include r($tablet) {
        margin: 0 -7px; }

    &__item {
        width: 33.33333%;
        padding: 15px;

        @include r($tablet) {
            width: 50%;
            padding: 7px; } } }




.dealer {
    background: $color-primary-darker;
    color: $white;
    padding: 15px;
    height: 100%; }
